import React from "react";
import { AboutUsPressInquiriesSerializer } from "web/types/serializers";
import * as styles from "./about-us-press-inquiries.css";

function AboutUsPressInquiries({
    title,
    content,
}: AboutUsPressInquiriesSerializer): React.ReactElement {
    return (
        <div className={styles.pressInquiries}>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
    );
}

export default AboutUsPressInquiries;
